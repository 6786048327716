import React from "react"

import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO title="404: Not found" />
		<div className="error">
			<div className="container">
				<h1 className="error-title"><FormattedMessage id="notfoundTitle" /></h1>
				<div dangerouslySetInnerHTML={{ __html: intl.messages.notfoundText}} />
				<p><FormattedMessage id="notfoundText2" /> <Link to="/"><FormattedMessage id="notfoundLink" /></Link>.</p>
			</div>
		</div>
  </Layout>
)

export default injectIntl(NotFoundPage)
